module.exports = [
   {
      "id": "start-date",
      "type": "start-date",
      "default": true,
      "exclude": [
         "/404/",
         "/",
         "/mba/",
         "/thank-you/"
      ]
   },
]